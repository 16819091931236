// noinspection DuplicatedCode

import React, {useEffect, useState} from "react";

interface AsyncComponentProps<T> {
    //Refresh frequency (in seconds)
    frequency: number;
    //Data loader function
    loader: () => Promise<T>;
    //Render function
    render: (data: T) => JSX.Element;
}

//An async component with automatic data refresh
export default function AsyncComponent<T>({frequency, loader, render}: AsyncComponentProps<T>) {
    if (frequency <= 0) {
        throw new Error("Frequency must be greater than 0");
    }
    const [data, setData] = useState<T | Error | null>(null);

    useEffect(() => {
        const load = async () => {
            try {
                let result = await loader();
                setData(result);
            }
            catch (e)
            {
                console.error(e);
                if (e instanceof Error)
                    setData(e);
            }
        }
        load().finally(() => {
            //After initial load, start refresh
            setInterval(load, frequency * 1000);
        });
    }, [loader, frequency]);

    if (!data) {
        return <p>Loading...</p>
    }
    if (data instanceof Error) {
        return <p className={"text text-danger"}>Error: {data.message}</p>
    }
    return render(data);
}