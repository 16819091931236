// noinspection DuplicatedCode

import React, {useEffect, useRef, useState} from "react";

interface AsyncComponentProps<T> {
    //Refresh frequency (in seconds)
    frequency: number;
    //Data loader function
    loader: () => Promise<T>;
    //Initial render function
    render: (data: T) => JSX.Element;
    //Function to update the rendered component
    update: ((element: JSX.Element, data: T) => void) | null;
}

//An async component with automatic data refresh that reuses initial rendered component
export default function AsyncUpdatableComponent<T>({frequency, loader, render, update}: AsyncComponentProps<T>) {
    if (frequency <= 0) {
        throw new Error("Frequency must be greater than 0");
    }

    const [data, setData] = useState<T | Error | null>(null);
    const elementRef = useRef<JSX.Element | null>(null);

    useEffect(() => {
        const load = async () => {
            try {
                let result = await loader();
                setData(result);
            }
            catch (e)
            {
                console.error(e);
                if (e instanceof Error)
                    setData(e);
            }
        }

        let interval: NodeJS.Timer | null = null;

        load().finally(() => {
            //After initial load, start refresh
            interval = setInterval(load, frequency * 1000);
        });
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [loader, frequency]);

    if (!data) {
        return <p>Loading...</p>
    }
    if (data instanceof Error) {
        return <p className={"text text-danger"}>Error: {data.message}</p>
    }
    if (elementRef.current) {
        if (update) {
            update(elementRef.current, data);
        }
    } else {
        elementRef.current = render(data);
    }
    return elementRef.current as JSX.Element;
}