import ApiClient, {ExtrusionFactory, Reading} from "../Api/ApiClient";
import AsyncComponent from "../Async/AsyncComponent";
import {CSSProperties} from "react";
import {Table} from "react-bootstrap";
import ReadingsTableOptions from "./ReadingsTableOptions";
import {useLoaderData} from "react-router-dom";
import {GetFactory} from "./DashboardOptions";

const intlFormat = new Intl.NumberFormat(
    'en-AU', 
    {
        minimumFractionDigits: 1,
        maximumFractionDigits: 2 
    });

const numFormat = (n: number | null | undefined) => n ? intlFormat.format(n) : "";

const centre: CSSProperties = {textAlign: "center"};

const getEstimateTagStyle = (r: Reading) : CSSProperties => !r.Line.HauloffMeasured && r.HaulOffSpeed ? {color: "darkgray"} : {};
    
const getLineName = (r: Reading) => r.Extruder.Name ? `${r.Line.Name} ${r.Extruder.Name}` : r.Line.Name;

const lineWarningText = "Maddington M1 & M4 hauloff are not measured, values are estimated from running job weight";
const lineWarning = <p style={{color: "gray", fontSize: '0.75em'}}>{lineWarningText}</p>;
    
const renderDesktop = (data: Reading[]) =>
    <div className="show-desktop">
        <h2>Current Readings</h2>
        <table className={"table table-striped table-fit"}>
            <thead>
            <tr>
                <th>Factory</th>
                <th>Line</th>
                <th style={centre} title="Throughput in Kg/Hr">Throughput</th>
                <th style={centre} title="Hauloff speed metres/minute">Hauloff</th>
                <th style={centre} title="Kilograms/metre">Kg/M</th>
                <th style={centre} title="Main RPM">RPM</th>
                <th style={centre} title="Average of root mean square kilowatts across all phases">Rms KW</th>
            </tr>
            </thead>
            <tbody>
            {data.map((r, i) => (
                <tr key={i}>
                    <td>{r.Line.Factory.Name}</td>
                    <td>{getLineName(r)}</td>
                    <td style={centre}>{numFormat(r.Throughput)}</td>
                    <td style={centre}><span style={getEstimateTagStyle(r)}>{numFormat(r.HaulOffSpeed)}</span></td>
                    <td style={centre}><span style={getEstimateTagStyle(r)}>{numFormat(r.KgPerMetre)}</span></td>
                    <td style={centre}>{numFormat(r.RPM)}</td>
                    <td style={centre}>{numFormat(r.RmsKiloWatts)}</td>
                </tr>
            ))}
            </tbody>
        </table>
        {lineWarning}
    </div>;
    
const renderMobile = (data: Reading[]) =>
    <div className="show-mobile">
        <h2>Current Readings</h2>
        {data.map((r, i) => (
            <div key={i}>
                <h1>{r.Line.Factory.Name} {getLineName(r)}</h1>
                <Table striped={true}>
                    <tbody>
                    <tr>
                        <td title="Throughput in Kg/Hr">Throughput</td>
                        <td>{numFormat(r.Throughput)}</td>
                    </tr>
                    <tr>
                        <td title="Hauloff speed metres/minute">Hauloff</td>
                        <td><span style={getEstimateTagStyle(r)}>{numFormat(r.HaulOffSpeed)}</span></td>
                    </tr>
                    <tr>
                        <td title="Kilograms/metre">Kg/M</td>
                        <td><span style={getEstimateTagStyle(r)}>{numFormat(r.KgPerMetre)}</span></td>
                    </tr>
                    <tr>
                        <td title="Main RPM">RPM</td>
                        <td>{numFormat(r.RPM)}</td>
                    </tr>
                    <tr>
                        <td title="Average of root mean square kilowatts across all phases">Rms KW</td>
                        <td>{numFormat(r.RmsKiloWatts)}</td>
                    </tr>
                    </tbody>
                </Table>
            </div>
            ))}
        {lineWarning}
    </div>;

export async function loadFactories() {
    const factories = await new ApiClient().getFactories();
    return {factories};
}

interface ReadingsTableProps {
    factories: ExtrusionFactory[]
}


export default function ReadingsTable() {
    const {factories} = useLoaderData() as ReadingsTableProps;
    
    const factoryId = GetFactory();
    const getReadings = async () => {
        let readings = await new ApiClient().getCurrentReadings();
        if (factoryId) {
            readings = readings.filter(r => r.Line.Factory.Id === factoryId);
        }
        return readings;
    }
    
    return (
        <div>
            <ReadingsTableOptions factories={factories}/>
            <AsyncComponent loader={getReadings} render={renderDesktop} frequency={30} />
            <AsyncComponent loader={getReadings} render={renderMobile} frequency={30} />
        </div>
    );
}

