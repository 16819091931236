import {z} from "zod";
import Config from "./Configuration";
import MsalClientApp, {MsalScopes} from "../Auth/MsalClientApp";

const factorySchema = z.object({
    Id: z.number(),
    Name: z.string()
});

const factoriesSchema = z.array(factorySchema);

const extruderSchema = z.object({
    Id: z.number(),
    Name: z.string().nullish()
});

const lineSchema = z.object({
    Id: z.number(),
    Name: z.string(),
    HauloffMeasured: z.boolean(),
    Factory: factorySchema,
    Extruders: z.array(extruderSchema)
});

const linesSchema = z.array(lineSchema);

const chartDataSchema = z.object({
    description: z.string(),
    dateFrom: z.string().transform(s => new Date(s)).nullish(),
    dateTo: z.string().transform(s => new Date(s)).nullish(),
    datasets: z.array(z.object({
        label: z.string(),
        borderColor: z.string(),
        data: z.array(z.object({
            x: z.string().transform(n => new Date(n)),
            y: z.number()
        }))
    }))
});

const readingsSchema = z.object({
    Line: lineSchema,
    Extruder: extruderSchema,
    Throughput: z.number().nullish(),
    HaulOffSpeed: z.number().nullish(),
    KgPerMetre: z.number().nullish(),
    RPM: z.number().nullish(),
    RmsKiloWatts: z.number().nullish()
});
const readingsArraySchema = z.array(readingsSchema);

export type ChartData = z.infer<typeof chartDataSchema>;
export type Reading = z.infer<typeof readingsSchema>;
export type ExtrusionFactory = z.infer<typeof factorySchema>;
export type ExtrusionLine = z.infer<typeof lineSchema>;
export type Extruder = z.infer<typeof extruderSchema>;

export default class ApiClient
{
    public async getFactories() : Promise<ExtrusionFactory[]>
    {
        const data = await this.get("Gravimetrics/Factories");
        return factoriesSchema.parse(data) as ExtrusionFactory[];
    }
    
    public async getLines() : Promise<ExtrusionLine[]>
    {
        const data = await this.get("Gravimetrics/Lines");
        return linesSchema.parse(data) as ExtrusionLine[];
    }
    
    public async getChartData(extruderId: number, period: string, differentColor: boolean) : Promise<ChartData>
    {
        const url = `Gravimetrics/ChartData/${period}/${extruderId}?differentColor=${differentColor}`;

        const data = await this.get(url);
        
        return chartDataSchema.parse(data);
    }

    public async getCurrentReadings() : Promise<Reading[]>
    {
        const data = await this.get("Gravimetrics/CurrentReadings");

        return readingsArraySchema.parse(data) as Reading[];
    }

    private async get(url: string) : Promise<any>
    {
        let account = MsalClientApp.getActiveAccount();
        if (!account)
        {
            throw new Error("User is not logged in");
        }
        const token = await MsalClientApp.acquireTokenSilent({
            scopes: MsalScopes,
            account: account
        });
        const request: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `${token.tokenType} ${token.accessToken}`,
            }
        }
        const response = await fetch(`${Config.ApiUrl}${url}`, request);
        if (!response.ok)
        {
            const err = `Failed to fetch ${url}.    ${response.status} ${response.statusText}`;
            console.log(err);
            throw new Error(err);
        }
        return await response.json();
    }
}