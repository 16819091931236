import { z } from "zod";

const configSchema = z.object({
    ApiUrl: z.string()
});

export type Configuration = z.infer<typeof configSchema>;

function getConfig() : Configuration
{
    if (process.env.NODE_ENV === "development") {
        return {
            //ApiUrl: "http://localhost:5574/api/",
            ApiUrl: "https://localhost:7160/api/" 
        };
    }
    return {
        ApiUrl: "/api/"
    }
}

const Config: Configuration = getConfig();

export default Config;