import ApiClient, {ExtrusionFactory, ExtrusionLine} from "../Api/ApiClient";
import {useLoaderData} from "react-router-dom";
import Dashboard from "./Dashboard";
import {CSSProperties} from "react";
import DashboardOption, {GetDifferentColor, GetFactory, GetPeriod} from "./DashboardOptions";

import './DashboardsList.css';

const wrapperStyle: CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
};

export interface ServerDashboardsProps {
    factories: ExtrusionFactory[];
    lines: ExtrusionLine[];
}

export async function loadLines() {
    const factories = await new ApiClient().getFactories();
    let lines = await new ApiClient().getLines();
    const factoryId = GetFactory();
    if (factoryId) {
        lines = lines.filter(l => l.Factory.Id === factoryId);
    }
    return {factories, lines};
}

export default function DashboardsList() {
    
    const {lines, factories} = useLoaderData() as ServerDashboardsProps;
    
    const period = GetPeriod();
    const diffColor = GetDifferentColor();
    
    return (
        <div>
            <DashboardOption factories={factories}/>
            <br/>
            <div style={wrapperStyle}>
                {lines.map(line => (
                    <div className="grav-dashboard" key={line.Id}>
                        <Dashboard period={period} differentColor={diffColor} frequency={60} line={line}/>
                    </div>)
                )}
            </div>
        </div>
    );
}