import React, {useRef} from "react";
import {ExtrusionFactory} from "../Api/ApiClient";

export function getQueryParam(name: string) {
    const params = new URLSearchParams(window.location.search);
    const p = params.get(name);
    if (p && p.length > 0) return p;
    return null;
}

export function GetPeriod() {
    return getQueryParam("period") ?? "Last2Days";
}

export function GetDifferentColor() {
    return getQueryParam("differentColor") === "true";
}

export function GetFactory() : number | null {
    const factory = getQueryParam("factory");
    if (!factory) return null;
    return parseInt(factory);
}

interface DashboardOptionsProps {
    factories: ExtrusionFactory[]
}

export default function DashboardOption(props: DashboardOptionsProps)
{
    const {factories} = props;
    const formRef = useRef<HTMLFormElement>(null);

    const period = GetPeriod();
    const factory = GetFactory();
    const differentColor = GetDifferentColor();

    const submit = () => {
        const form = formRef.current;
        if (!form) {
            return;
        }
        const period = form["period"].value;
        const differentColor = form["differentColor"].checked;
        const factory = form["factory"].value;
        window.location.href = `/Dashboard?period=${period}&factory=${factory}&differentColor=${differentColor}`;
    };

    return (
        <form ref={formRef} style={{marginTop: '0.5em'}}>
            <div className="row">
                <div className="col-sm-auto row">
                    <label htmlFor="period" className="col-sm-auto col-form-label">Period:</label>
                    <div className="col-sm-auto">
                        <select name="period"
                                id="period"
                                className="form-select"
                                value={period}
                                onChange={submit}>
                            <option value="LastDay">Last 24 Hours</option>
                            <option value="Last2Days">Last 2 Days</option>
                            <option value="Last3Days">Last 3 Days</option>
                            <option value="Last7Days">Last 7 Days</option>
                        </select>
                    </div>
                </div>
                <div className="col-sm-auto row">
                    <label htmlFor="period" className="col-sm-auto col-form-label">Factory:</label>
                    <div className="col-sm-auto">
                        <select name="factory"
                                id="factory"
                                className="form-select"
                                value={factory?.toString()}
                                onChange={submit}>
                            <option value="">All</option>
                            {factories.map(f => <option key={f.Id} value={f.Id}>{f.Name}</option>)}
                        </select>
                    </div>
                </div>
                <div className="col-sm-auto row" style={{marginLeft: '3em'}}>
                    <div className="form-check">
                        <input name="differentColor"
                               type="checkbox"
                               id="color"
                               className="form-check-input"
                               checked={differentColor}
                               onChange={submit}/>
                        <label htmlFor="color" className="form-check-label">Different Color</label>
                    </div>
                </div>
            </div>
        </form>
    );
}

