import React, {useRef} from 'react';

import {
    Chart as ChartJS,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

//@ts-ignore
import 'chartjs-adapter-date-fns';
import {Line} from 'react-chartjs-2';

import ApiClient, {ChartData, ExtrusionLine} from "../Api/ApiClient";
import AsyncUpdatableComponent from "../Async/AsyncUpdatableComponent";

ChartJS.register(
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface DashboardProps {
    data: ChartData,
    chartRef: React.MutableRefObject<any>
}

const initialRender = ({data,chartRef}: DashboardProps) =>
    <Line ref={chartRef}
          data={data}
          options={
              {
                  responsive: true,
                  maintainAspectRatio: false,
                  elements: {
                      point: {
                          radius: 0,
                          hitRadius: 10
                      },
                      line: {
                          tension: 0.25
                      }
                  },
                  scales: {
                      x: {
                          type: 'time',
                          time: {
                              unit: 'hour',
                              displayFormats: {
                                  hour: 'EEE h:mm a'
                              }
                          }
                      },
                      y: {
                          beginAtZero: true
                      }
                  },
                  plugins: {
                      legend: {
                          position: 'top' as const,
                      },
                      title: {
                          display: true,
                          font: {
                              size: 20
                          },
                          text: data.description
                      }
                  },
              }}/>

const updateData = ({data,chartRef}: DashboardProps) => {
    const chart = chartRef.current as ChartJS;
    if (!chart) return;
    chart.data = data as any;
    chart.options!.plugins!.title!.text = data.description;
    chart.update();
    return chart;
}

interface DashboardParameters {
    //Period to display
    period: string;
    //Whether each SKU should have a different color
    differentColor: boolean;
    //Refresh frequency (in seconds)
    frequency: number;
    //Server Id
    line: ExtrusionLine
}

export default function Dashboard({frequency, line, period, differentColor}: DashboardParameters) {
    const chartRef = useRef(null);

    const getData = () => new ApiClient().getChartData(line.Id, period, differentColor);

    return <AsyncUpdatableComponent frequency={frequency}
                                    loader={getData}
                                    render={data => initialRender({chartRef, data})}
                                    update={(_,data) => updateData({data,chartRef})}/>;
}

