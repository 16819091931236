
import {
    AccountInfo,
    AuthenticationResult, BrowserCacheLocation,
    Configuration,
    EventMessage,
    EventType, InteractionRequiredAuthError,
    PublicClientApplication, RedirectRequest, SilentRequest
} from "@azure/msal-browser";
import Config from "../Api/Configuration";

import msalClientApp from "./MsalClientApp";


import {z} from "zod";


const azureAdOptionsSchema = z.object({
    Authority: z.string(),
    ClientId: z.string(),
    ApplicationId: z.string()
});

type AzureAdOptions = z.infer<typeof azureAdOptionsSchema>;

function getOptions() : AzureAdOptions
{
    const url = `${Config.ApiUrl}Authentication/AzureAdOptions`;
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, false);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.send();
    if (xhr.status !== 200) {
        console.error("Failed to get Azure AD options");
    }
    const o = JSON.parse(xhr.responseText);
    return azureAdOptionsSchema.parse(o);
}

const options : AzureAdOptions = getOptions();

//Setup MSAL
const conf : Configuration = {
    auth: {
        clientId: options.ClientId,
        authority: options.Authority,
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage //Persist login
    }
}

export const MsalScopes = [ `${options.ApplicationId}/Api` ];

//Acquires a token silently if possible, otherwise acquires a token interactively
async function AcquireToken(app: PublicClientApplication, account: AccountInfo) {
    try {
        const request: SilentRequest = {
            scopes: MsalScopes,
            account: account,
            //Force a refresh of the token, which will reload roles
            forceRefresh: true
        };
        await app.acquireTokenSilent(request);
    }
    catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
            //User interaction is required
            
            const request: RedirectRequest = {
                scopes: MsalScopes,
                account: account
            };
            
            //This causes a redirect, hence never returns
            await app.acquireTokenRedirect(request);
            throw new Error("Redirected to acquire token");
        }
        
        //Unknown error, re-throw
        throw e;
    }
}

export const MsalClientApp = new PublicClientApplication(conf);

export default MsalClientApp;

export async function InitializeMsal(): Promise<PublicClientApplication>
{
    await MsalClientApp.initialize();

    //On login, set the active account to the account that logged in
    MsalClientApp.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            MsalClientApp.setActiveAccount(account);
        }
    });

    //Check if there is a token in the cache
    let account = msalClientApp.getActiveAccount();
    if (account) {
        await AcquireToken(MsalClientApp, account);
        return MsalClientApp;
    }

    let result = await MsalClientApp.handleRedirectPromise();

    if (result?.account)
    {
        //If there is an account, ensure we can acquire a token silently
        await AcquireToken(MsalClientApp, result.account);
    }
    else {
        // User is not logged in, redirect to login page
        await MsalClientApp.loginRedirect({scopes: MsalScopes});
        throw new Error("Redirected to login page");

    }

    return MsalClientApp;
}