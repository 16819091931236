import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {InitializeMsal} from "./Auth/MsalClientApp";
import {MsalProvider} from "@azure/msal-react";
import CreateRouter from "./Router";
import {RouterProvider} from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

InitializeMsal().then(app => {
    root.render(
        <React.StrictMode>
            <MsalProvider instance={app}>
                <RouterProvider router={CreateRouter()}/>
            </MsalProvider>
        </React.StrictMode>
    );
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
