
import {createBrowserRouter,} from "react-router-dom";
import App from "./App";
import ReadingsTable, {loadFactories} from "./Dashboard/ReadingsTable";
import DashboardsList, {loadLines} from "./Dashboard/DashboardsList";

export default function CreateRouter() {
    return createBrowserRouter([
        {
            path: "/",
            element: <App />,
            children: [
                {
                    path: "Readings",
                    loader: loadFactories,
                    element: <ReadingsTable />
                },
                {
                    path: "Dashboard",
                    loader: loadLines,
                    element: <DashboardsList />
                },
                {
                    path: "",
                    loader: loadLines,
                    element: <DashboardsList />
                }
            ]
        },
    ]);
}