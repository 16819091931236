import React from 'react';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import {InteractionType} from "@azure/msal-browser";
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication} from "@azure/msal-react";

import {Outlet} from "react-router-dom";
import {Authentication} from "./Auth/Authentication";
import {Container, Nav, Navbar, NavItem} from "react-bootstrap";

// Gets an escaped query parameter from the URL
function GetEncodedQueryParam(name: string) {
    const params = new URLSearchParams(window.location.search);
    const p = params.get(name);
    if (p && p.length > 0) return encodeURIComponent(p);
    return "";
}

export default function App() {
    useMsalAuthentication(InteractionType.Redirect);

    const period = GetEncodedQueryParam("period");
    const differentColor = GetEncodedQueryParam("differentColor");
    const factory = GetEncodedQueryParam("factory");
    const params = `period=${period}&factory=${factory}&differentColor=${differentColor}`;
    
    return (
        <div style={{ margin: "1em"}}>
            <Navbar expand="sm">
                <Container>
                    <Navbar.Brand href="/">Gravimetrics</Navbar.Brand>
                    <Navbar.Toggle aria-controls="main-nav"/>
                    <Navbar.Collapse id="main-nav">
                        <Nav className="me-auto">
                            <Nav.Link href={`/Dashboard?${params}`}>Dashboard</Nav.Link>
                            <Nav.Link href={`/Readings?${params}`}>Readings</Nav.Link>
                        </Nav>
                        <Authentication/>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <AuthenticatedTemplate>
                <Outlet/>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <p>You must login to continue!</p>
            </UnauthenticatedTemplate>
        </div>
    )
}
