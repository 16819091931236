import React, {useRef} from "react";
import {ExtrusionFactory} from "../Api/ApiClient";
import {GetDifferentColor, GetFactory, GetPeriod} from "./DashboardOptions";

interface ReadingsTableOptionsProps {
    factories: ExtrusionFactory[]
}

export default function ReadingsTableOptions(props: ReadingsTableOptionsProps)
{
    const {factories} = props;
    const formRef = useRef<HTMLFormElement>(null);

    const factory = GetFactory();

    const submit = () => {
        const form = formRef.current;
        if (!form) {
            return;
        }
        const period = GetPeriod();
        const differentColor = GetDifferentColor();
        const factory = form["factory"].value;
        window.location.href = `/Readings?factory=${factory}&period=${period}&differentColor=${differentColor}`;
    };

    return (
        <form ref={formRef} style={{marginTop: '0.5em'}}>
            <div className="col-sm-auto row">
                <label htmlFor="period" className="col-sm-auto col-form-label">Factory:</label>
                <div className="col-sm-auto">
                    <select name="factory"
                            id="factory"
                            className="form-select"
                            value={factory?.toString()}
                            onChange={submit}>
                        <option value="">All</option>
                        {factories.map(f => <option key={f.Id} value={f.Id}>{f.Name}</option>)}
                    </select>
                </div>
            </div>
        </form>
    );
}

